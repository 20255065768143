<template>
  <!--支付中弹窗-->
  <van-dialog v-if="showPayLoading" :show="showPayLoading" title="等待支付"
              confirm-button-text="支付成功" cancel-button-text="取消支付" show-cancel-button
              @confirm="payLoadingCheck" @cancel="payLoadingCheck">
    <wallet-loading :payData="payData" ref="walletLoadingRef" @callBack="closePayLoading"></wallet-loading>
  </van-dialog>
  <div v-else class="main has-bottom">
    <van-nav-bar class="top-bar" title="钱包" left-arrow @click-left="onClickLeft"/>
    <div class="wallet-head">
      <div class="wallet-title">账户钱包余额</div>
      <div class="van-ellipsis wallet-amount">￥{{balance}}</div>
    </div>
    <van-row class="wallet-cell">
      <van-col class="wallet-col" v-for="(item,index) in walletRow" :key="index" span="8">
        <div class="col-box" :class="query.amount == item ? 'checked' : ''" @click="setAmount(item)">{{ item }}元</div>
      </van-col>
    </van-row>
    <van-field class="wallet-input" v-model="checkAmount"
               :class="!walletRow.includes(query.amount) ? 'checked' : ''" placeholder="自定义其他金额（1-50000)" @focus="setAmount(checkAmount)"></van-field>

    <!--三方支付-->
    <van-radio-group v-model="query.pay_code">
      <van-cell-group class="cell-list">
        <div v-for="(item, key) in payConfig" :key="key" class="cell-div">
          <van-cell clickable @click="choosePayWay(key)">
            <template #title>
              <van-icon :name="item.icon" class="cell-title-img"/>
              <div class="cell-title">{{ item.title }}</div>
            </template>
            <template #right-icon>
              <van-radio :name="key"/>
            </template>
          </van-cell>
        </div>
      </van-cell-group>
    </van-radio-group>
    <!--底部按钮-->
    <div class="bottom-bar">
      <van-button type="primary" round @click="onRecharge" :loading="payLoading"
                  loading-text="支付中...">去充值</van-button>
    </div>
  </div>
</template>

<script>
import {Row, Col, NavBar, Button, Cell, CellGroup, RadioGroup, Radio, Icon, Field,Dialog, Toast} from 'vant';
import {reactive, ref, computed} from "vue";
import validator from "@/utils/formsValidator";
import {payBalance} from "@/api/balance";
import walletLoading from "@/views/personal/WalletLoading";
import {useStore} from "vuex";
import "@/style/common.css"

export default {
  name: "Wallet",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    walletLoading,
  },
  setup() {
    const store = useStore()
    store.commit('updateUserInfo')

    const balance = computed(() => {return store.state.userInfo.balance})
    const walletRow = [50, 100, 200, 300, 1000, 2000]
    const payLoading = ref(false)
    const query = reactive({
      amount: 50,
      pay_code: 'alipay'
    })
    const checkAmount = ref('')
    const amountRules = {
      label: '充值金额',
      rules: 'range(1~50000)'
    }
    //支付方式列表
    const payConfig = reactive({
      alipay: {
        title: "支付宝",
        icon: require('@/assets/images/pay/zfb-pay.png'),
      },
      wechat: {
        title: "微信支付",
        icon: require('@/assets/images/pay/wx-pay.png'),
      },
    });
    //支付弹窗
    const showPayLoading = ref(false)
    const walletLoadingRef = ref()
    const payData = reactive({
      pay_order_number: "",
      pay_url: ''
    })
    //返回操作
    const onClickLeft = () => history.back();
    //选择充值金额
    const setAmount = (data) => {
      query.amount = data
      checkAmount.value = walletRow.includes(query.amount) ? '' : checkAmount.value
    }
    //选择支付方式
    const choosePayWay = (payType) => {
      query.pay_code = payType
    }
    //提交充值
    const onRecharge = () => {
      payLoading.value = true
      if (checkAmount.value) {
        let {isValid, msg} = validator.isValid(checkAmount.value, amountRules)
        if (!isValid) {
          Toast(msg)
          payLoading.value = false
          return false
        }
        query.amount = checkAmount.value
      }

      payBalance(query).then(({data}) => {
        payData.pay_order_number = data.pay_order_number
        payData.pay_url = data.pay_extend
        showPayLoading.value = true
      }).finally(()=>{
        payLoading.value = false
      })

    }
    //loading 按钮
    const payLoadingCheck = () => {
      walletLoadingRef.value.checkOrderStatus(true)
    }
    const closePayLoading = () => {
      showPayLoading.value = false
    }


    return {
      balance,
      walletRow,
      payLoading,
      showPayLoading,
      walletLoadingRef,
      payData,
      checkAmount,
      query,
      payConfig,
      amountRules,
      onClickLeft,
      setAmount,
      choosePayWay,
      onRecharge,
      payLoadingCheck,
      closePayLoading,
    };
  },
}
</script>

<style scoped>
.wallet-head {
  margin: 16px auto;
  width: 343px;
  padding: 20px;
  border-radius: 20px;
  background: url("../../assets/images/personal/wallet-head.png") no-repeat;
  background-size: 100%;
  text-align: left;
  overflow: hidden;
  box-sizing: border-box;
}

.wallet-title {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  margin-bottom: 12px;
}

.wallet-amount {
  height: 42px;
  font-size: 30px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42px;
}

.wallet-cell {
  padding: 6px 8px;
}

.wallet-col {
  padding: 6px 8px;
}
.col-box{
  line-height: 48px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  font-size: 16px;
  font-weight: 500;
  color: #4E5969;
  cursor: pointer;
}
.col-box.checked{
  border-color: #845EC2;
  color:#845EC2;
}
.wallet-input{
  line-height: 48px;
  background: #FFFFFF;
  padding:6px 16px;
}
.wallet-input :deep(.van-cell__value){
  border: 1px solid #DCDCDC;
  padding:0px 12px;
  font-size: 14px;
}
.wallet-input::after{
  display: none;
}
.cell-list {
  margin-top: 14px;
  border-radius: 8px;
  overflow: hidden;
}

.cell-list::after {
  display: none;
}

.cell-list .van-cell {
  height: 48px;
}

.cell-list :deep(.van-cell__left-icon) {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.cell-list :deep(.van-cell__title) {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
}

.cell-list :deep(.van-cell__value) {
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
}

.cell-list :deep(.van-cell__right-icon) {
  font-size: 12px;
  color: #1D2129;
}

.cell-list :deep(.van-checkbox__icon), .cell-list :deep(.van-radio__icon) {
  height: 20px;
  line-height: 20px;
}

.cell-list :deep(.van-checkbox__icon .van-icon), .cell-list :deep(.van-radio__icon .van-icon) {
  width: 20px;
  height: 20px;
  font-size: 16px;
}

.cell-list :deep(.van-checkbox__label) {
  margin-left: 8px;
}

.cell-list .cell-div {
  position: relative;
}

.cell-list .cell-div::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid #DCDCDC;
}

.cell-title-img {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.bottom-bar .van-button {
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 17px;
}
</style>
